@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;400;500;600;700;800&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Sora", sans-serif;
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* GENERAL STYLES */

.btn-primary {
  @apply bg-primary hover:bg-secondary rounded-full text-white px-10 py-3;
}

.btn-primary-admin {
  @apply bg-primary rounded-full text-white px-10 py-3 text-sm font-normal;
}

.btn-primary-admin-outline {
  @apply bg-none border border-primary text-primary rounded-full  px-10 py-3 text-sm font-normal;
}

.btn-primary-admin-outline:disabled {
  @apply bg-none border border-gray-400 text-gray-400 cursor-not-allowed rounded-full  px-10 py-3 text-sm font-normal;
}

.btn-danger {
  @apply bg-red-800 rounded-full text-white px-10 py-3 text-sm font-normal;
}
.btn-primary-admin:disabled {
  @apply bg-primary bg-opacity-70 cursor-not-allowed;
}

.btn-primary:disabled {
  @apply bg-primary bg-opacity-50 cursor-not-allowed rounded-full text-white px-10 py-3;
}

.btn-secondary {
  @apply bg-secondary hover:bg-primary rounded-full text-white px-10 py-3;
}

.btn-primary-white {
  @apply bg-white rounded-full px-6 py-3 hover:bg-secondary hover:text-white;
}

/* navigation */

.nav-links li {
  @apply inline mx-5 cursor-pointer;
}

.form-input-full {
  @apply appearance-none border-none bg-white border  border-primary text-primary block w-full  rounded-xl py-4 px-6 mb-3 leading-tight focus:border-secondary;
}

.admin-input {
  @apply appearance-none text-base font-normal  bg-white border  border-primary  text-primary block w-full  rounded-xl py-4 px-6 mb-3 leading-tight focus:border-secondary;
}

/* Footert */

.footer {
  @apply max-w-7xl md:mx-auto  bg-purple px-5 pt-20 md:m-10 md:p-20 rounded-none md:rounded-3xl  text-primary;
}

.footer-links li {
  @apply text-primary  hover:text-secondary my-2   cursor-pointer;
}

.footer-header {
  @apply text-xl font-bold;
}

.footer-links {
  @apply mb-7 md:mb-1;
}

.nav-item {
  @apply cursor-pointer hover:text-primary;
}
.nav-item-mobile {
  @apply cursor-pointer hover:text-primary mb-5;
}

/* NAVIGATION LINKS */
.nav-link-menu {
  @apply block my-5 py-3 text-white font-light px-4 text-base  rounded-2xl transition duration-200;
}

.nav-link-menu-active {
  @apply block my-5 py-3 shadow-md text-primary font-light px-4 text-base rounded-2xl  bg-white transition duration-200;
}

.nav-icon {
  @apply ml-5 inline;
}

.nav-active-icon {
  @apply ml-5 inline;
}

.pill {
  @apply bg-purple text-primary px-4 py-2 text-xs font-semibold rounded-full;
}

label {
  @apply text-sm block mb-2 font-normal text-primary;
}

.product-bubble {
  @apply bg-purple rounded-2xl p-10;
}

/* SKELETON LOADERS */

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.prod-label {
  @apply text-xs font-normal my-1;
}

.prod-header {
  @apply text-3xl font-semibold;
}
